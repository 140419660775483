<template>
  <div id="page">
    <Header active_tab="assessment"></Header>
    <SearchBar
      @searched="searched"
      style="margin-top: 10px"
      class="wrap"
      place-holder="Search for test"
    ></SearchBar>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <SimplifiedCard
          v-for="item in filteredListFun"
          :key="item.id"
          :comp="item"
          :page_id="page_id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";
import { getTestReference, testTakers } from "@/Utils/global";

import { defineAsyncComponent } from "vue";
const Header = defineAsyncComponent(() => import("@/components/Header"));
const Card = defineAsyncComponent(() => import("@/components/Card"));
const SearchBar = defineAsyncComponent(() => import("@/components/SearchBar"));
const SimplifiedCard = defineAsyncComponent(() =>
  import("@/components/SimplifiedCard")
);
export default {
  //This page only has quizes that are submitted by user till now
  name: "Results",
  props: ["page_id"],
  components: {
    Card,
    Header,
    SearchBar,
    SimplifiedCard,
  },
  async mounted() {
    const ref = getTestReference(this.page_id);

    //Show only tests which are uploaded ain last year only
    const date = new Date();
    const days_to_subtract = 365;
    const date2 = new Date(
      date.valueOf() - days_to_subtract * 24 * 60 * 60 * 1000
    );
    const snap = await ref
      .where("start", "<=", date)
      .where("start", ">=", date2)
      .where("deleted", "==", 0)
      .orderBy("start", "desc")
      .get(); //Test has already begin
    /////////////////////////////
    snap.forEach(async (elem) => {
      var userDet = await testTakers(
        this.page_id,
        elem.id,
        store.getters.user.data.uid
      );
      //All quiz in this time period
      var doc = await ref.doc(elem.id).get();
      const data = doc.data();
      let id = elem.id.toString();
      let total = data.total;
      let title = data.title;
      let start = data.start.toDate();
      start = start.toDateString();
      let score, given, reviewed;

      if (!userDet.exists) {
        score = -1;
        given = false;
        reviewed = false;
        this.list.push({
          id: id,
          title: title,
          date: start,
          score: score,
          total: total,
          given: given,
          reviewed: reviewed,
        });
      } else if (userDet.exists && userDet.data().responses == null) {
        //Test has started not completed yet
        score = -1;
        given = false;
        reviewed = false;
        this.list.push({
          id: id,
          title: title,
          date: start,
          score: score,
          total: total,
          given: given,
          reviewed: reviewed,
        });
      }
    });
    store.state.loading = false;
  },
  data() {
    return {
      list: [],
      searchQuery: "",
    };
  },
  methods: {
    searched(data) {
      // console.log(data);
      this.searchQuery = data;
    },
  },
  computed: {
    filteredListFun() {
      if (this.searchQuery) {
        return this.list.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.list;
      }
    },
  },
};
</script>
<style scoped>
#page {
  width: 100%;
  min-height: 100%;
  position: absolute;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%, red);
  background-attachment: fixed;
}
</style>
